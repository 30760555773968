.TitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
}

.Title {
    font-size: 60px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 70px;
}

.TitleUnderline {
    width: 113px;
    height: 12px;
    background-color: #bad739;
    margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
    .Title {
        font-size: 15px;
        line-height: 30px;
        font-weight: bold;
        margin-top: 55px;
        margin-bottom: 0;
    }

    .TitleUnderline {
        width: 90px;
        height: 5px;
        margin-bottom: 20px;
    }
}