@import "~antd/dist/antd.less";
@import "~jexcel/dist/jexcel.css";

:root {
  --main-text-color: #7f7f7f;
  --main-menu-color: #000000;
  --standard-color: #bad739;
  --active-color: #e4f71b;
  --page-bg-color: #bfbfbf;
  --second-bg-color: #ed7d31;
  --second-text-color: #ffffff;
  --second-shadow-color: #cc6c2a;
  --element-shadow-color: #ccc;

  --main-font-size: 18px;
  --text-color-primary: #000000;
  --text-color-secondary: #808080;
  --text-color-light: #ffffff;
  --text-color-highlighted: #bad739;
  --text-color-highlighted2: #d6642e;
  --text-color-portal: #007cb0;
  --bg-color-page: #ffffff;
  --bg-color-primary: #ffffff;
  --bg-color-sec: #d3d3d3;
  --bg-color-cookies: #007cb0;
  --bg-color-modal: #495b63;
  --bg-color-input: #495b63;
  --btn-color: #bad739;
  --btn-color-highlighted: #e4f71b;
  --border-color: #c4c4c4;
}

html {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: var(--main-font-size);
  line-height: 1.17;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color-primary);
  background-color: var(--bg-color-page);
}

.es-layout {
  background-color: var(--bg-color-page);
  min-height: 100vh;
}

.content {
  margin-top: 84px;
}

@media screen and (max-width: 1200px) {
  .content {
    margin-top: 140px;
  }
}

/* Tabs */

.ant-tabs {
  font-size: inherit;
  margin: 0 auto;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  max-width: 1080px;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.ant-tabs-nav-list {
  justify-content: space-between;
}

.esp-register-user-tabs.ant-tabs {
  width: calc(100% - 130px);
}

.ant-tabs-tab {
  width: 50%;
  margin: 0;
  font-size: 16px;
  padding: 15px 0 8px;
  font-weight: 700;
  color: var(--text-color-secondary);
  text-transform: uppercase;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 20px 0 15px;
  font-size: 18px;
  font-weight: 700;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.ant-tabs-tab-active {
  border-bottom: 1px #ffffff solid;
}

.ant-tabs-tab-active .es-tab-title-second {
  border-bottom: 2px solid var(--text-color-portal);
}

.ant-tabs-tab-active .es-tab-title::before,
.ant-tabs-tab-active .es-tab-title::after {
  position: absolute;
  bottom: -1px;
  content: "";
  display: block;
  width: 1px;
  height: 95%;
  background-color: #000000;
}

.ant-tabs-tab-active .es-tab-title::before {
  left: -1px;
}

.ant-tabs-tab-active .es-tab-title::after {
  right: 0;
}

.ant-tabs-tab .es-tab-first::before,
.ant-tabs-tab .es-tab-last::after {
  display: none;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0 #000000 solid;
}

.ant-tabs-tab-btn {
  width: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
  text-shadow: none;
  font-weight: 700;
}

.ant-tabs-tab.ant-tabs-tab-active .add {
  color: #000000;
}

.ant-tabs-ink-bar {
  height: 0;
  background: transparent;
}

.ant-tabs-ink-bar::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background: var(--active-color);
  width: 100%;
}

.ant-tabs-content-holder {
  width: 100%;
}

.ant-tabs-content {
  padding-top: 0;
}

@media (min-width: 1200px) {
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    font-size: 24px;
  }
}

.es-tab-title {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  color: var(--text-color-secondary);
  padding: 0 55px;
}

.es-tab-title-mini {
  padding: 0 15px;
}

a.es-tab-title:hover {
  border-bottom: transparent 2px solid;
}

.es-tab-title-main {
  margin: 0 auto 10px auto;
  font-size: 48px;
  font-weight: 700;
  border-bottom: transparent 2px solid;
  line-height: 100%;
  white-space: normal;
}

.es-tab-title.es-tab-first .es-tab-title-main,
.es-tab-title.es-tab-first .es-tab-title-add1 {
  color: var(--text-color-highlighted);
}

.es-tab-title.es-tab-last .es-tab-title-main,
.es-tab-title.es-tab-last .es-tab-title-add1 {
  color: var(--text-color-highlighted2);
}

.es-tab-title-second {
  border-bottom: transparent 2px solid;
  margin: 0 auto;
}

.ant-tabs-tab-active .es-tab-title-second {
  color: var(--text-color-portal);
}

.es-tab-title-second:hover,
.es-tab-title-main:hover {
  border-bottom: var(--text-color-highlighted) 2px solid;
}

.es-tab-title-add1 {
  margin: 10px auto;
  padding: 0 10%;
  font-size: 24px;
  font-weight: 700;
  white-space: normal;
}

.es-tab-title-add2 {
  margin: 5px auto;
  padding: 0 10%;
  font-size: 18px;
  font-weight: 400;
  white-space: normal;
}

.ant-tabs-tab-active .es-tab-title-main,
.ant-tabs-tab-active .es-tab-title-add1 {
  color: var(--text-color-highlighted);
}

.ant-tabs-tab-active .es-tab-title-add2 {
  color: var(--text-color-primary);
}

@media (max-width: 1199px) {
  .ant-tabs {
    width: calc(100% - 40px);
    margin: 10px auto;
  }

  .esp-register-user-tabs.ant-tabs {
    width: calc(100% - 40px);
  }

  .esp-standard-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: column;
  }

  .esp-standard-tabs .ant-tabs-tab {
    width: 100%;
    border-top: 0;
  }

  .esp-standard-tabs .ant-tabs-tab-active .es-tab-title::before,
  .esp-standard-tabs .ant-tabs-tab-active .es-tab-title::after {
    display: none;
  }

  .esp-standard-tabs .ant-tabs-tab-active {
    background-color: var(--bg-color-page);
  }

  .es-tab-title {
    padding: 0 10px;
  }

  .es-tab-title-main {
    font-size: 24px;
    white-space: normal;
  }

  .es-tab-title-add1 {
    font-size: 20px;
    padding: 0;
  }

  .es-tab-title-add2 {
    font-size: 16px;
    padding: 0;
  }
}

.ant-tabs-nav-operations {
  display: none !important;
}

/* Spinner */

.ant-spin-spinning {
  position: absolute;
}

.ant-spin-dot {
  font-size: 40px;
}

.ant-spin {
  color: var(--text-color-highlighted);
}

.ant-spin-dot-item {
  color: var(--text-color-highlighted);
  background-color: var(--text-color-highlighted);
}

/* Links & buttons */

a {
  text-decoration: none;
  border-bottom: transparent 2px solid;
  padding-bottom: 0.1em;
  color: var(--text-color-highlighted);
}

a:hover,
a:focus,
a:active {
  border-bottom: var(--text-color-highlighted) 2px solid;
  color: var(--text-color-highlighted);
}

a.nodecor {
  padding-bottom: 0;
  border-bottom: none;
}

a.nodecor:hover,
a.nodecor:focus,
a.nodecor:active {
  border-bottom: none;
}

.ant-btn {
  min-width: 180px;
}

.es-link-btn-navigation,
.es-link-btn-no-auth {
  display: block;
  text-align: center !important;
  color: #000000 !important;
  background-color: var(--btn-color) !important;
  border: 1px solid var(--btn-color) !important;
  font-size: 14px !important;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 100% !important;
  border-radius: 30px !important;
}

.es-link-btn-navigation:hover,
.es-link-btn-navigation:focus,
.es-link-btn-navigation:active,
.es-link-btn-no-auth:hover,
.es-link-btn-no-auth:focus,
.es-link-btn-no-auth:active {
  background-color: var(--btn-color-highlighted) !important;
  border: 1px solid var(--btn-color-highlighted) !important;
  color: #000000 !important;
}

a.es-link-btn,
a.ant-btn-link {
  display: block;
  padding: 11px 20px;
  text-align: center;
  color: var(--text-color-secondary);
  background-color: white;
  border: 2px solid var(--btn-color);
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 100%;
  border-radius: 5px;
}

a.es-link-btn:hover,
a.es-link-btn:focus,
a.es-link-btn:active {
  background-color: var(--btn-color);
  color: white;
}

a.ant-btn {
  padding: 11px 20px;
  line-height: 100%;
}

a.es-link-noul {
  border-bottom: none !important;
  padding-bottom: 0;
}

.es-link-btn-large {
  width: 220px;
}

.es-link-btn-center {
  margin: 0 auto;
}

/* Forms */

.ant-form label {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
}

@media screen and (max-width: 1200px) {
  .esp-register-form {
    width: 100%;
  }

  .ant-picker {
    width: 100% !important;
  }
}

.esp-register-form .ant-row {
  display: flex;
  justify-content: center;
}

.esp-register-form .ant-form-item-label {
  width: 200px;
  text-align: left;
}

.esp-register-form .ant-form-item-control-input {
  text-align: center;
}

@media (min-width: 1200px) {
  .esp-register-form .ant-form-item-label {
    margin-right: 45px;
    text-align: left;
    color: white;
    text-transform: lowercase;
  }

  .esp-register-form .esp-register-input .ant-form-item-control {
    width: 350px;
    max-width: 350px;
  }

  .esp-register-form .esp-register-button .ant-form-item-control-input {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    /*padding-left: 195px;*/
  }

  .esp-register-form .esp-register-label5-button .ant-form-item-control-input {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 230px;
  }
}

@media (max-width: 1199px) {
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.ant-form-item-label > label {
  color: white;
  font-size: var(--main-font-size);
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-top: -15px;
  margin-right: 7px;
  background-image: url("/star.svg");
  background-size: 14px 13px;
  background-repeat: no-repeat;
  width: 14px;
  height: 13px;
  content: "";
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}

.ant-picker {
  border-radius: 10px;
  width: 180px;
}

.ant-input-affix-wrapper {
  border-radius: 10px;
}

.ant-input {
  border-radius: 10px;
  border-color: white;
  background-color: transparent;
  color: white;
}

.ant-input::placeholder {
  color: white;
}

.ant-input:hover {
  border-color: var(--text-color-highlighted);
  border-right-width: 1px;
}

.ant-input:focus, .ant-input-focused {
  border-color: var(--text-color-highlighted);
}

.ant-input-affix-wrapper {
  border-radius: 10px;
  background-color: transparent;
}

.ant-input-affix-wrapper > .ant-input {
  color: white;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--text-color-highlighted);;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: transparent;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: transparent;
}

.ant-select-selector {
  border-color: white !important;
  background-color: transparent !important;
  color: white !important;
}

.ant-select-arrow {
  color: white;
}

.ant-picker {
  border-color: white !important;
  background-color: transparent !important;
  color: white !important;
}

.ant-picker-input input {
  border-color: white !important;
  background-color: transparent !important;
  color: white !important;
}

.ant-upload {
  border-radius: 50% !important;
}

/* Modals */

.ant-modal-content {
  background-color: var(--bg-color-modal);
  border-radius: 15px;
}

.ant-modal-header {
  background-color: var(--bg-color-modal);
  border-radius: 15px 15px 0 0;
  border-bottom: none;
  color: var(--second-text-color);
}

.ant-modal-title {
  color: var(--second-text-color);
}

.ant-modal-close {
  color: var(--second-text-color);
}

.ant-modal-close:hover, .ant-modal-close:focus {
  color: var(--active-color);
}

/* Result */

.ant-result-title {
  color: white;
}

.ant-result-subtitle {
  color: white;
}

.ant-result-success .ant-result-icon > .anticon {
  color: var(--text-color-highlighted);
}
@primary-color: #1DA57A;