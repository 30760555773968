.Footer {
    background-color: black;
    height: 92px;
}

.FooterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 92px;
    max-width: 1280px;
    width: 1280px;
    margin: 0 auto;
}

.FooterCopyright {
    font-size: 14px;
    color: white;
    margin: 0;
    padding: 10px 0;
}

.FooterLinks {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
}

.FooterSocialWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    column-gap: 0;
    width: 150px;
}

.FooterSocial {
    color: white;
    border-bottom: none;
}

.FooterSocial:hover {
    border-bottom: none;
}

.FooterLink {
    color: white;
    font-size: 14px;
    border-bottom: none;
}

@media screen and (max-width: 1200px) {
    .Footer {
        height: 200px;
    }

    .FooterContainer {
        flex-direction: column;
        max-width: 325px;
        width: 325px;
    }

    .FooterSocialWrapper {
        padding-top: 10px;
    }

    .FooterSocial {
        margin-bottom: 20px;
    }

    .FooterLinks {
        flex-direction: column;
        align-items: center;
    }
}