.MainBg {
    background-image: url("../assets/cabinet-bg.png");
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 92px - 84px);
}

.MainContainer {
    width: 1080px;
    margin: 0 auto;
    padding: 38px 45px;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.GradientCard {
    /*background: linear-gradient(180deg, rgba(73, 91, 99, 0.85) 0%, #172227 100%);*/
    background: #495B63;
    width: 990px;
    border-radius: 15px;
    overflow: hidden;
}

.PublicProfileCard {
    position: relative;
}

.Container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.AvatarWrapper {
    margin-bottom: 50px;
}

.MainSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    column-gap: 30px;
}

.PlayerDataWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.HomeAvatarWrapper {
    width: 270px;
    display: flex;
    justify-content: center;
}

.HomeAvatarItem {
    width: auto;
    height: 480px;
}

.MainInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
}

.UserInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 41px;
    width: 100%;
}

.UserInfoLabel {
    width: 36px;
    margin-right: 32px;
}

.UserInfoValue {
    font-size: 20px;
    line-height: 24px;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
    max-width: 195px;
    overflow: hidden;
}

.RatingInfo {
    /*
    width: 570px;
    height: 270px;

     */
    width: 270px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.25) 100%);
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.RatingItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 285px;
}

.RatingItemLabel {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.RatingItemValue {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    color: var(--standard-color);
    margin-bottom: 0;
}

.RatingItemValueBold {
    color: var(--standard-color);
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
    margin-bottom: 0;
}

.LeaderboardSection {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 30px;
}

.LeaderboardCard {
    width: 270px;
    height: 433px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) -3.23%, rgba(255, 255, 255, 0.25) 96.77%);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LeaderboardTitle {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
}

.LeaderboardLogo {
    width: 200px;
    height: 140px;
}

.LeaderboardRatingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LeaderboardRatingItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LeaderboardRatingItemLabel {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.LeaderboardRatingItemValue {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    color: #BAD739;
    margin-bottom: 0;
}

.LeaderboardRatingItemValueBold {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 72px;
    text-align: center;
    color: #BAD739;
    margin-bottom: 0;
}

.FooterSection {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 30px;
    margin-bottom: 30px;
}

.FooterItem {
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.QRCodeImage {
    border-radius: 15px;
}

.FooterSocialWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    column-gap: 18px;
    color: white;
    margin-bottom: 23px;
}

.FooterSocial {
    color: white;
    border-bottom: none;
}

.FooterSocial:hover {
    border-bottom: none;
}

.FooterSocialDescription {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.FooterSocialLink {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    text-decoration: none;
}

.FooterSocialLink:hover {
    border-bottom: none;
}

.BigFlagWrapper {
    width: 990px;
    height: 660px;
    position: absolute;
    left: 0;
    top: -1px;
    z-index: 1;
}

.UserNameWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 67px;
    z-index: 2;
    background: rgba(23, 34, 39, 0.6);
    border-radius: 15px 15px 0 0;
    margin-bottom: 34px;
}

.UserName {
    color: white;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    flex: 1;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    margin-bottom: 0;
}

.TesLogo {
    width: 300px;
    height: 39px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
    .MainContainer {
        width: 325px;
        padding: 30px 0;
    }

    .GradientCard {
        width: 325px;
    }

    .Container {
        align-items: center;
    }

    .AvatarWrapper {
        margin-bottom: 34px;
    }

    .UserNameWrapper {
        height: 45px;
        margin-bottom: 0;
    }

    .UserName {
        color: white;
        font-size: 22px;
        max-width: 250px;
        overflow: hidden;
    }

    .MainSection {
        flex-direction: column;
        align-items: center;
        margin-top: 21px;
        row-gap: 30px;
        width: 100%;
    }

    .PlayerDataWrapper {
        width: 100%;
        column-gap: 0;
    }

    .HomeAvatarWrapper {
        width: 50%;
    }

    .HomeAvatarItem {
        width: auto;
        height: 300px;
    }

    .MainInfo {
        width: 50%;
    }

    .UserInfoItem {
        display: flex;
        flex-direction: row;
        align-items: start;
        margin-bottom: 25px;
    }

    .UserInfoLabel {
        width: 18px;
        margin-right: 10px;
    }

    .UserInfoValue {
        font-size: 16px;
        margin-top: 0;
        max-width: 115px;
    }

    .RatingInfo {
        width: 300px;
        height: 190px;
    }

    .RatingItem {
        margin-top: 0;
        align-items: center;
        width: 148px;
        padding: 0;
        margin-bottom: 0;
    }

    .RatingItemLabel {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .RatingItemValue {
        font-size: 20px;
        line-height: 38px;
    }

    .RatingItemValueBold {
        font-size: 32px;
        line-height: 46px;
    }

    .LeaderboardSection {
        width: 300px;
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }

    .LeaderboardRatingWrapper {
        flex-direction: row;
        align-items: start;
        column-gap: 40px;
    }

    .LeaderboardRatingItemLabel {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .LeaderboardRatingItemValue {
        font-size: 46px;
        line-height: 58px;
    }

    .LeaderboardRatingItemValueBold {
        font-size: 46px;
        line-height: 58px;
    }

    .LeaderboardCard {
        width: 300px;
        height: 320px;
    }

    .BigFlagWrapper {
        width: 325px;
        height: 244px;
    }

    .FooterSection {
        flex-direction: column;
    }

    .FooterItem {
        margin-bottom: 15px;
    }
}