.Overlay {
    display: flex;
    position: fixed;
    z-index: 100;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.VideoWrapper {
    position: relative;
}

.CloseIcon {
    position: absolute;
    top: -40px;
    right: 0;
    width: 40px;
    height: auto;
    cursor: pointer;
}