.ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    column-gap: 20px;
}

.FormContainer {
    display: flex;
    align-items: start;
    justify-content: space-around;
}

.FormPart {
}

.EditCountryContainer {
    display: flex;
    align-items: flex-start;
}

.FieldContainer {
    width: 375px;
}

.FlagContainer {
    margin-left: 18px;
    margin-top: 4px;
    width: 32px;
    font-size: 24px;
    line-height: 0;
}

@media screen and (max-width: 1200px) {
    .ButtonsWrapper {
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10px;
    }

    .FieldContainer {
        width: auto;
    }

    .FlagContainer {
        margin-top: 46px;
        width: 40px;
        font-size: 24px;
    }
}
