.Container {
    display: flex;
    justify-content: center;
}

.Player {
    width: 772px;
    height: 1000px;
}

.InfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 583px;
}

.TennisBallLogo {
    width: 240px;
    height: 240px;
    margin-bottom: 90px;
}

.EsportLogo {
    width: 367px;
    height: 48px;
    margin-bottom: 64px;
}

.Header {
    width: 367px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    color: #BAD739;
}

.Text {
    width: 367px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 32px;
}