.HeaderContainer {
  position: relative;
  height: unset;
  background: #ffffff;
  padding: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  color: var(--text-color-primary);
}

@media all and (min-width: 1200px) {
  .HeaderContainer {
    flex-direction: row;
    justify-content: space-between;
    height: 115px;
  }
}

.Menu {
}

.Menu a {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 100%;
  color: #000000;  
}

.Menu a:hover {
  color: #000000;  
}

.Ep {
  color: var(--text-color-highlighted) !important;
}

.Ep:hover {
  color: var(--text-color-highlighted) !important;
}

.Middle {
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 10;
}
  
.Slogan {
  font-size: 36px;
  font-weight: 700;
  color: var(--text-color-portal);
  line-height: 120%;
}

.SloganMobile {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-color-portal);
  line-height: 120%;
  text-align: center;
  margin: 30px 0px;
  padding: 0px 50px;
}

.Username {
  font-size: 18px;
  font-weight: 700;
  color: var(--text-color-primary);
}

.Username:hover{
  color: var(--text-color-primary);
  border-bottom: 2px solid white;
}

.AvatarLink {
  margin: 0px 20px 0px 40px;
}

.Avatar {
  border: 2px solid var(--text-color-highlighted);
  z-index: 2;
}

.EnterLink {
  font-size: 40px;
  line-height: 100%;
}

.Icon {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.MenuMobileContainer {
  position: relative;
  width: 100%;
  min-height: 50px;
}

.AvatarInnerWrap{
  width: 100%;
  height: 100%;
  position: relative;
  top: -80px
}

.Toggle {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 15px;
  min-width: 0px;
  padding: 0px 0px;
  background-color: transparent;
  background-image: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  cursor: pointer;
  outline: none;  
}

.Toggle:hover,
.Toggle:focus {
  background-color: transparent;
}

.IconBar {
  display: block;
  width: 40px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--text-color-secondary);
}

.IconBar:not(:first-child) {
  margin-top: 7px;
}

.MenuMobile {
  position: relative;
  padding-top: 20px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #fff;
}

.MenuMobile a {
  font-size: 24px;
}

.MenuMobile a:not(:first-child) {
    margin-top: 10px;
    margin-left: 0px;
}

@media (max-width: 1199px) {
  .Middle {
    display: none;
  }
  
  .Icon {
    position: absolute;
    margin: 0px;
    top: 52px;
    right: 48px;
  }
  
  .AvatarLink {
    margin: 0px !important;
    position: absolute;
    top: 33px;
    left: 190px;
  }

  .Username {
    font-size: 20px;
    color: var(--text-color-secondary);
    min-height: 40px;
    display: flex;
    align-items: center;
  }

}

@media all and (min-width: 1200px) {
  .Menu {
    width: 100%;
    margin-top: 10px;
    line-height: 100%;
    display: flex;
    justify-content: flex-start;
    
  }
  .Menu a:not(:first-child) {
    margin-left: 40px;
  }

  .MenuMobileContainer,
  .Toggle,
  .SloganMobile {
    display: none !important;
  }   

  .MenuMobile {
    display: none !important;
  }
  
}

@media all and (min-width: 1200px) {
  .Personal {
  }
}

.AvatarBadge {
	width: 35px;
	height: 35px;
	border-radius: 20px;
	font-size: 14px;
	background-color: #E04300;
	position: absolute;
	top: 58px;
	left: 58px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
  z-index: 1;
}