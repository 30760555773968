.pc-loader {
  display: block;
  position:  fixed;
  top:       50%;
  left:      50%;
  transform: translate(-50%, -50%);
}

.ant-spin-dot-item {
  background: #1f2229;
}
