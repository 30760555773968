.Container {
  display: block;
  position: relative;
  padding: 20px 0px;
   
}

.FooterLogoLink {
  margin: 0px 20px;
  max-width: 100px;
  max-height: 35px;
}

.FooterLogo {
  max-height: 35px;
  max-width: 100px;
  z-index: 2;
  position: relative;
}

.FooterCopyright {
  width: 150px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
}

.FooterNav {
  flex-grow: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Link {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  color: #000000;
  font-weight: 400;
}

.Link:hover {
  color: #000000;
}

.FooterSocialList {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0px 20px;
}

.FooterSocialList li:not(:last-child) {
  margin-right: 20px;
}

.FooterSocialList li {
  opacity: 0.8;
  max-height: 30px;
}

.FooterSocialList li:hover {
  opacity: 1;
}

.FooterSocialList li a img {
  max-height: 30px;
  max-width: 30px;
}

.FooterSocialList li a {
  text-decoration: none;
  max-height: 30px;
  max-width: 30px;
}

.GreenDiv {
  height: 30px;
  background-color: var(--text-color-highlighted);
}

@media (max-width: 1199px) {
  .FooterLogoLink {
    display: block;
    margin: 0px auto;
  }
  
  .FooterCopyright {
    width: 100%;
    margin: 20px auto;
  }

  .Link {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  
  .FooterSocialList {
    width: 100%;
    margin: 0px;
  }
}


@media (min-width: 1200px) {
  .Container {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  
  .FooterNav {
    flex-direction: row;
    justify-content: center;
  }

  .Link {
    margin: 10px 20px 0px 20px;
  }
  
}


