.Container {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 50px;
    max-width: 670px;
}

.VideoContainer {
    position: relative;
    margin-bottom: 48px;
}

.VideoPreview {
    width: 670px;
    height: 350px;
}

.OpenVideo {
    position: absolute;
    top: 130px;
    left: 290px;
    width: 100px;
    height: auto;
    z-index: 3;
    cursor: pointer;
}

.Text {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
    text-align: right;
    color: #FFFFFF;
}

.BottomContainer {
    display: flex;
    justify-content: end;
    align-items: center;
}

.OculusIcon {
    width: 100px;
    height: 21px;
    margin-left: 10px;
}

.AvailableText {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #FFFFFF;
    padding-top: 12px;
    margin-left: 144px;
}