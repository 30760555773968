.Button {
    color: black;
    font-weight: bold;
    padding: 10px 24px;
    background-color: #bad739;
    border-radius: 30px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    min-width: 0;
    border: none;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.Button:hover {
    background-color: #e4f71b;
    border: none;
    color: black;
}