.MainInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.MainInfoAvatarContainer {
    margin-top: 32px;
    margin-bottom: 24px;
}

.MainInfoAvatar {
    border: 2px solid var(--text-color-highlighted);
    z-index: 2;
}

.MainInfoNickName {
    font-size: 24px;
    line-height: 28px;
    font-family: 'Rubik', sans-serif;
    color: var(--text-color-highlighted);
}

.MainInfoHeader {
    margin-top: 40px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #FF0000;
    text-transform: uppercase;
}

.MainInfoStat {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

}

.MainInfoStatItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 32px;
}

.MainInfoStatHeader {
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
}

.MainInfoStatValue {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #BAD739;
    margin-top: 30px;
}

.MainInfoControl {
    margin-top: 44px;
    display: flex;
}

.Icon {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    color: #FFFFFF;
    cursor: pointer;
}

.Tabs {
    align-items: center;
}

.TabTitle {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    margin: 0 20px;
}

.LoaderContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 244px);
}

.LoaderText {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    margin: 0 auto;
    color: white;
    padding-top: 110px;
}

/* new styles */
.MainBg {
    background-image: url("../assets/cabinet-bg.png");
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 92px - 84px);
}

.MainContainer {
    width: 1080px;
    margin: 0 auto;
    padding: 38px 45px;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.PlayerCardsContainer {
    display: flex;
    flex-direction: row;
    grid-column-gap: 30px;
    column-gap: 30px;
}

.PlayerCardsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.GradientCard {
    /*background: linear-gradient(180deg, rgba(73, 91, 99, 0.85) 0%, #172227 100%);*/
    background: #495B63;
    width: 480px;
    border-radius: 15px;
}

.LeaderboardCard {
    position: relative;
}

.MyAccountCard {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 650px;
}

.UserNameWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 67px;
    z-index: 2;
    background: rgba(23, 34, 39, 0.6);
    border-radius: 15px 15px 0 0;
    margin-bottom: 34px;
    padding: 0 10px;
}

.UserName {
    color: white;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    flex: 1;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    margin-bottom: 0;
    overflow: hidden;
}

.MainInfoWrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.MainInfoColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.AvatarWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

.PlayType {
    margin-top: 225px;
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.PlayTypeName {
    font-size: 32px;
    color: #FFFFFF;
    font-weight: 400;
    width: 300px;
    text-align: right;
    margin-right: 6px;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
}

.PlayerImage {
    width: auto;
    height: 480px;
    margin-bottom: 10px;
}

.CardFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.CardChangeBtn {
    width: 50px;
    height: 50px;
}

.CardChangeBtn img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.CardFooterText {
    width: 100%;
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    text-align: center;
    position: relative;
    z-index: 2;
}

.StatisticWrapper {
    width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
}

.RatingTitle {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-top: 15px;
}

.RatingsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 60px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb25 {
    margin-bottom: 25px;
}

.RatingsCell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.RatingItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 33px;
}

.RatingLabel {
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 11px;
    color: #FFFFFF;
}

.RatingValue {
    color: var(--standard-color);
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 47px;
}

.RatingValueBold {
    color: var(--standard-color);
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
}

.ArrowLeft {
    font-size: 40px;
    font-family: 'Rubik', sans-serif;
    position: absolute;
    top: 25px;
    left: 36px;
    color: white;
    cursor: pointer;
}

.ArrowRight {
    font-size: 40px;
    font-family: 'Rubik', sans-serif;
    position: absolute;
    top: 25px;
    right: 36px;
    color: white;
    cursor: pointer;
}

.LeaderboardName {
    text-transform: uppercase;
    font-size: 36px;
    font-family: 'Rubik', sans-serif;
    font-weight: 900;
    text-align: center;
    width: 100%;
}

.RatingImg {
    width: 200px;
    height: 140px;
}

.UserInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 41px;
    width: 100%;
}

.UserInfoLabel {
    width: 36px;
    margin-right: 32px;
}

.mtn5 {
    margin-top: -14px !important;
}

.UserInfoValue {
    font-size: 20px;
    line-height: 24px;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
    max-width: 155px;
    overflow: hidden;
}

.UserInfoBigValue {
    font-size: 32px;
    color: var(--standard-color);
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 0px;
}

.QRCodeImage {
    border-radius: 15px;
}

.mt0 {
    margin-top: 0px !important;
}

.HeaderSocial {
    color: white;
    border-bottom: none;
}

.HeaderSocial:hover {
    border-bottom: none;
}

.NavigationCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 32px 12px;
}

.SettingsItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
}

.SettingsItemLabel {
    font-size: 20px;
    color: #FFFFFF;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 0;
}

.PlayerCardLink {
    font-size: 24px;
    text-transform: uppercase;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin: 0;
    cursor: pointer;
}

.BigFlagWrapper {
    width: 480px;
    height: 360px;
    position: absolute;
    left: 0;
    top: -1px;
    z-index: 1;
}

.Data {
    z-index: 2;
}

.SocialMediaWrapper {
    margin-top: 45px;
    margin-bottom: 32px;
}

@media screen and (max-width: 1200px) {
    .MainContainer {
        width: 325px;
        padding: 30px 0;
        justify-content: center;
    }

    .PlayerCardsContainer {
        flex-direction: column;
        row-gap: 30px;
    }

    .GradientCard {
        width: 325px;
    }

    .StatisticWrapper {
        width: 300px;
    }

    .UserNameWrapper {
        height: 45px;
        margin-bottom: 0;
    }

    .PlayerCardsWrapper {
        flex-direction: column;
        row-gap: 30px;
    }

    .UserName {
        color: white;
        font-size: 22px;
        max-width: 300px;
        overflow: hidden;
    }

    .PlayTypeName {
        font-size: 22px;
    }

    .PlayType {
        margin-top: 125px;
    }

    .MyAccountCard {
        min-height: 500px;
        padding: 0;
    }

    .PlayerImage {
        height: 300px;
        width: auto;
    }

    .UserNameInBack {
        font-size: 20px;
    }

    .UserInfoLabel {
        width: 18px;
        margin-right: 10px;
    }

    .UserInfoValue {
        font-size: 16px;
        margin-top: 0;
        max-width: 120px;
    }

    .UserInfoBigValue {
        font-size: 18px;
    }

    .UserBackWrapper {
        padding: 10px 12px;
    }

    .UserBackColumn {
        width: 120px;
    }

    .mtn5 {
        margin-top: -4px !important;
    }

    .RatingImg {
        width: 160px;
        height: 112px;
    }

    .RatingsRow {
        margin-bottom: 16px;
    }

    .RatingsCell {
        width: 50%;
    }

    .RatingItem {
        margin-top: 16px;
    }

    .RatingLabel {
        font-size: 16px;
    }

    .RatingValue {
        font-size: 20px;
    }

    .RatingValueBold {
        font-size: 32px;
        line-height: 46px;
    }

    .RatingValueRankBold {
        font-size: 28px;
    }

    .LeaderboardName {
        font-size: 18px;
    }

    .RatingTitle {
        font-size: 18px;
        margin-top: 21px;
    }

    .SettingsItem {
        margin-top: 16px;
    }

    .SettingsItem img {
        width: 22px;
        height: 22px;

    }

    .SettingsItemLabel {
        font-size: 16px;
    }

    .PlayerCardLink {
        font-size: 18px;
    }

    .BigFlagWrapper {
        width: 325px;
        height: 244px;
    }

    .AvatarWrapper {
        margin-bottom: 25px;
    }

    .UserInfoItem {
        margin-bottom: 25px;
    }

    .SocialMediaWrapper {
        margin-top: 35px;
        margin-bottom: 15px;
    }

    .LoaderText {
        font-size: 16px;
        line-height: 30px;
        padding-top: 130px;
    }
}
