.Background {
    width: 100%;
    height: 770px;
    background-repeat: no-repeat;
    background-image: url("/design/bckgnd_testimonial_2.png");
    background-position: center;
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 1080px;
    margin: 0 auto;
}