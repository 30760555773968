.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ButtonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 285px;
    margin-top: 40px;
}

.Text {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-bottom: 50px;
}

.SaveButtonWrapper {
    margin-bottom: 24px;
}
