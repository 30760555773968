.Logo {
}

.Logo img {
	height: 110px;
  z-index: 2;
	margin: 20px;
  position: relative;
  left: -110px;
  top: 0px;
}

@media all and (min-width: 1200px) {
  .Logo img {
    height: 90px;
    width: 90px;
    margin: 0px 20px;
    position: static;
  }
}
