.HeaderContainer {
    display: block;
    z-index: 2;
    height: 84px;
    position: absolute;
    width: 100%;
    background: #080018 url("/design/header_1.png") no-repeat center;
}

.ContentContainer {
    max-width: 1280px;
    width: 1280px;
    margin: 0 auto;
}

.Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.FirstRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.SecondRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.HeaderLogoLink {
    display: flex;
    align-items: center;
    max-width: 213px;
    border-bottom: none !important;
    padding-bottom: 0;
}

.HeaderLogoLink img {
    max-width: 213px;
    max-height: 28px;
    z-index: 99999;
}

.NavigationLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.NavigationLink {
    color: white;
    border-bottom: none;
    margin: 2px 15px 0;
    font-size: 20px;
}

.LangSelect {
    outline: none;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    width: 60px;
}

.LangSelect:hover, .LangSelect:focus {
    outline: none;
}


.HeaderSignIn {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
}

.HeaderSignIn svg {
    cursor: pointer;
}

.LoginLink {
    outline: none;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.ProfileLink, .ProfileLink:hover {
    padding-bottom: 0;
    border-bottom: none;
    line-height: 14px;
}


@media screen and (max-width: 1200px) {
    .HeaderContainer {
        background: url("/design/header_mobile_1.png") no-repeat center;
        height: 140px;
    }

    .ContentContainer {
        max-width: 325px;
        width: 325px;
    }

    .Wrapper {
        flex-direction: column;
    }

    .FirstRow {
        justify-content: space-between;
    }

    .SecondRow {
        margin-top: 20px;
        justify-content: space-between;
    }

    .HeaderLogoLink {
        max-width: 150px;
    }

    .HeaderLogoLink img {
        max-width: 120px;
    }

    .NavigationLink {
        margin: 2px 4px 0;
        font-size: 12px;
    }

    .LangSelect {
        margin-bottom: 8px;
        margin-top: 5px;
        display: none;
    }
}