/* Fonts */

/* Basic styles */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* jExcel */

.jexcel_toolbar i.jexcel_toolbar_item {
  padding: 0 !important;
}

@media (min-width: 575px) {
  .ant-collapse .ant-form-item-label {
    min-width: 200px !important;
    text-align: left;
  }
}

