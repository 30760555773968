.SocialWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    column-gap: 22px;
    color: white;
}

.Social {
    font-size: 24px;
    color: white;
    border-bottom: none;
}

.Social:hover {
    border-bottom: none;
}

.TennisEsport {
    width: 35px;
    height: 35px;
    cursor: pointer;
}


@media screen and (max-width: 1200px) {
    .SocialWrapper {
        column-gap: 15px;
    }

    .Social {
        font-size: 20px;
        text-align: center;
    }

    .TennisEsport {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}