.Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.FormContainer {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FormTitle {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 18px;
    padding-bottom: 25px;
}

.GradientCard {
    background: linear-gradient(180deg, rgba(73, 91, 99, 0.85) 0%, #172227 100%);
    width: 990px;
    border-radius: 15px;
    padding: 23px;
    overflow: hidden;
}

.CardWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.CartTitle {
    color: #FFFFFF;
    font-size: 32px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 800;
    width: 100%;
}

.CarouselColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}

.ProfileColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 640px;
}

.CarouselImg {
    margin-top: 50px;
    width: auto;
    height: 480px;
}

.CarouselNavigation {
    color: #FFFFFF;
    font-size: 32px;
    font-family: "Roboto", sans-serif;
    margin-top: 50px;
}

.Left:hover, .Right:hover {
    color: #BAD739;
}

.CarouselNavigation span {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .GradientCard {
        width: 300px;
    }

    .CardWrapper {
        flex-direction: column;
    }

    .CarouselColumn {
        width: 100%;
    }

    .ProfileColumn {
        width: 100%;
    }

    .CarouselImg {
        margin-top: 0;
        width: auto;
        height: 380px;
    }
}
