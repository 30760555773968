.HeaderContainer {
    display: flex;
    width: 50%;
    margin-top: 60px;
    align-items: center;
    justify-content: center;
}

.ContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}