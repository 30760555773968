.AvatarUpload > .ant-upload {
	width: 128px;
	height: 128px;
}

.AvatarUploadCenter{
	display: flex !important;
	justify-content: center;
}

.AvatarImg {
	width: 100px;
	height: 100px;
	border-radius: 50px;
}
