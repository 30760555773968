.Background {
    position: relative;
    width: 100%;
    background: #0b4142 url("/design/bckgnd_instantcup.jpg") center top / cover no-repeat;
    min-height: calc(100vh - 168px);
    background-size: calc(max(100%, 1000px));
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1080px;
    margin: 0 auto;
}

@media screen and (max-width: 1200px) {
    .Background {
        background-position: left;
        height: 500px;
    }

    .Container {
        align-items: center;
        justify-content: center;
        width: 390px;
    }
}